:root {
  --bs-secondary-color: rgba(231, 231, 231, 0.75);
  --bs-secondary-color-rgb: 231, 231, 231;
  --bs-dark-rgb: 40,40,40;
  --bs-body-font-size: 0.9rem;
  --bs-link-color: #282828;
  --bs-link-color-rgb: 40,40,40;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #111111;
  --bs-link-hover-color-rgb: #111111;
  
}

.btn-primary {
    --bs-btn-color: #e7e7e7;
  --bs-btn-bg: #ee2624;
  --bs-btn-border-color: #ee2624;
  --bs-btn-hover-color: #e7e7e7;
  --bs-btn-hover-bg: #cc1310;
  --bs-btn-hover-border-color: #cc1310;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #e7e7e7;
  --bs-btn-active-bg: #cc1310;
  --bs-btn-active-border-color: #cc1310;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #e7e7e7;
  --bs-btn-disabled-bg: #ee2624;
  --bs-btn-disabled-border-color: #ee2624;  
}

.btn-outline-primary {
    --bs-btn-color: #9055A2;
    --bs-btn-border-color: #9055A2;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #9055A2;
    --bs-btn-hover-border-color: #9055A2;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #9055A2;
    --bs-btn-active-border-color: #9055A2;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #9055A2;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #9055A2;
    --bs-gradient: none;
}

.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: none;
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,border-radius 0.15s ease;
  --bs-accordion-border-color: none;
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23212529' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='none' stroke='%23052c65' stroke-linecap='round' stroke-linejoin='round'%3e%3cpath d='M2 5L8 11L14 5'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-box-shadow: none;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #282828;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(170,170,170,.25);
}

.form-check-input:focus {
  border-color: #282828;
  outline: 0;
  box-shadow: 0 0 0 .25rem rgba(170,170,170,.25);
}

.form-check-input:checked {
  background-color: #282828;
  border-color: #282828;
}

.btn {
    --bs-btn-font-size: 0.9rem;
}

.accordion {
  --bs-accordion-active-color:#e7e7e7;
  --bs-accordion-active-bg: rgb(40, 40, 40);
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23e7e7e7' viewBox='0 0 16 16'%3E%3Cpath d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/%3E%3C/svg%3E");
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23282828' viewBox='0 0 16 16'%3E%3Cpath d='m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z'/%3E%3C/svg%3E");
}

.accordion-button:not(.collapsed)::after .bi-caret-down-fill {
  fill: blue !important;
  stroke:blue !important;
}



[data-bs-theme="dark"] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222,226,230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33,37,41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255,255,255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222,226,230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52,58,64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222,226,230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43,48,53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: grey;
    --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110,168,254;
  --bs-link-hover-color-rgb: 139,185,254;
  --bs-code-color: #e685b5;
  --bs-highlight-color: #dee2e6;
  --bs-highlight-bg: #664d03;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

.active>.page-link {
    background-color: #b4b4b4;
}

.list-group {
  --bs-list-group-active-bg: #ee2624;
  --bs-list-group-active-border-color: #ee2624;
}

.navbar {
  --bs-navbar-brand-font-size: 0.9rem;
}

.form-control {
  font-size: 0.9rem;
}

#root {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight:400;
  font-style: normal;  
}

.logo_evo7 {
    width:77%;
    height:auto;
}

.logo_header {
    width:177px;
    height:auto;
}

.nmb_evo7 {
    position: absolute;
    bottom:0;
    left:0;
    width:37%;
    height:auto;
    max-width:220px;
    opacity: 0.55;
    z-index:-1;
}

.slogan_evo7 {
    position: absolute;
  top: 0;
  margin-top: -4px;
  left: 50%;
  margin-left: -148px;
  width: 37%;
  height: auto;
  max-width: 280px;
}

.cart-link {
    text-decoration: none;
}

.porduct-name {
        margin: 0; /* Убираем внешний отступ */
    -webkit-line-clamp: 3; /* Число отображаемых строк */
    display: -webkit-box; /* Включаем флексбоксы */
    -webkit-box-orient: vertical; /* Вертикальная ориентация */
    overflow: hidden; /* Обрезаем всё за пределами блока */
}

.product-img {
    width:100%;
}

.form-select:focus {
  border-color: #86b7fe;
  border:none;
  outline: 0;
  box-shadow: none;
}

.filter-panel {
    background-color:#f5f5f5;
}

.mobile-base-btn {
    font-size:1.177rem;
}

.btn:hover {
  color:#f7f7f7;
}

.breadcrumb-item + .breadcrumb-item::before {
  color:#282828;
}

.breadcrumb-item.active {
  color:#c8c8c8;
}
